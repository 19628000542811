p {
  font-size: 19px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
.wp-block-image {
  margin: 0;
  padding: 0;
  max-width: 100%; /* Make figure responsive */
  overflow: hidden; /* Prevent overflow */
}

.wp-block-image figure image {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}

.wp-block-image figure {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table {
  border-collapse: collapse;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  margin-bottom: 2rem;
}
.wp-block-table .has-fixed-layout tr {
  border-collapse: collapse;
  word-break: normal;
}
.wp-block-table .has-fixed-layout td {
  word-break: break-word;
  padding: 0.5em;
  border: 1px solid #6b6765;
}
.has-text-align-left {
  text-align: left;
}
.has-text-align-center {
  text-align: center;
}

.wp-block-table figcaption {
  font-size: 80%;
  text-align: center;
}
